<template>
    <div class="search-form" data-testid="search-block-form">
        <SearchField class="search-form__category">
            <SearchAutocompleteCategory v-model="category" :placeholder="categoryPlaceholder" />
        </SearchField>
        <SearchField class="search-form__location">
            <VJoyIcon name="location" color="neutral" />
            <SearchAutocompleteLocation v-model="location" :placeholder="locationPlaceholder" :remote-country-label="locationRemoteCountryLabel" />
            <VJoyButton class="search-form__submit" variant="main" icon="search" icon-position="right" :disabled="!hasQuery" @click.stop="submit">
                {{ submitText }}
            </VJoyButton>
        </SearchField>
    </div>
</template>

<script setup lang="ts">
    import {ref, computed} from 'vue';
    import {VJoyIcon, VJoyButton} from '@maltjoy/core-vue';
    import SearchField from './SearchField.vue';
    import SearchAutocompleteCategory from './SearchAutocompleteCategory.vue';
    import SearchAutocompleteLocation from './SearchAutocompleteLocation.vue';
    import useSearchHelpers from '../composables/useSearchHelpers';
    import type {LocationSuggestion} from '../types/custom';

    const props = defineProps<{
        categoryPlaceholder: string | null;
        locationPlaceholder: string | null;
        locationRemoteCountryLabel?: string | null;
        submitText: string | null;
        submitLink: string | null;
        briefOrigin?: string;
    }>();

    const {getSearchURL} = useSearchHelpers();

    const category = ref('');
    const location = ref<LocationSuggestion | string>('');
    const hasQuery = computed(() => category.value?.length >= 3);

    function submit() {
        window.location.href = getSearchURL({
            baseURLLink: props.submitLink || '/s',
            query: category.value,
            location: (location.value as LocationSuggestion) || null,
            sourceComponent: props.briefOrigin,
        });
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .search-form {
        position: relative;
        border: 2px solid var(--joy-color-neutral-30);
        border-radius: var(--joy-core-radius-4);
        margin-bottom: calc(var(--joy-form-field-height-medium) + var(--joy-core-spacing-4));

        &:after {
            content: '';
            display: block;
            background-color: var(--joy-color-neutral-30);
            position: absolute;
            top: var(--joy-core-spacing-12);
            left: var(--joy-core-spacing-3);
            height: 1px;
            width: calc(100% - 2 * var(--joy-core-spacing-3));
        }
        &:has(input:focus):after {
            opacity: 0;
        }

        .joy-icon {
            pointer-events: none;
        }

        .search-autocomplete {
            flex: 1;
            align-self: stretch;
            height: var(--joy-core-spacing-12);
        }

        &__submit.joy-button {
            position: absolute;
            top: calc(100% + 2px); // add form border width
            left: -2px; // aligned with form border
            width: calc(100% + 4px); // add form border width
            margin: var(--joy-core-spacing-4) 0;
        }

        /* RESPONSIVE */
        @include mq.screen_sm(min) {
            height: 100%;
            display: flex;
            align-items: stretch;
            margin-bottom: 0;
            border-radius: var(--joy-core-radius-5);

            &:after {
                height: 70%;
                width: 1px;
                top: 15%;
                left: 40%;
            }
            &__category {
                width: 40%;
                flex: none;
            }
            &__submit.joy-button {
                position: static;
                width: auto;
                min-height: var(--joy-form-field-height-small);
                font-size: var(--joy-font-size-primary-300);
                margin: var(--joy-core-spacing-3) 0;
            }
        }

        @include mq.screen_md(min) {
            &:after {
                left: 45%;
            }
            &__category {
                width: 45%;
            }
        }
    }
</style>
