<template>
    <div class="search-tab-panel">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .search-tab-panel {
        position: relative;
        padding: var(--joy-core-spacing-4);
        border: 1px solid var(--joy-color-neutral-30);
        border-radius: 0 var(--joy-core-radius-4) var(--joy-core-radius-4) var(--joy-core-radius-4);
        background-color: white;

        @include mq.screen_md(min) {
            padding: 26px;
        }
    }
</style>
