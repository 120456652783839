<template>
    <div class="search-panel">
        <SearchTabs :value="activeTab">
            <SearchTab
                v-for="(tab, i) in tabs"
                :key="i"
                :class="tab.classes"
                :value="tab.value"
                :label="tab.label"
                :icon="tab.icon"
                :tag="tab.tag"
                :active="activeTab === tab.value"
                @click="onTabClick(tab)"
            />
        </SearchTabs>
        <SearchTabPanel>
            <SearchForm
                v-if="activeTab === 'keywords'"
                :category-placeholder="`${props.searchFieldCategory}`"
                :location-placeholder="`${props.searchFieldLocation}`"
                :location-remote-country-label="props.searchLocationRemoteCountryLabel"
                :submit-text="t('index.btn.search')"
                :submit-link="`${props.searchSubmitLink}`"
                :brief-origin="`${props.briefOrigin}`"
            />
        </SearchTabPanel>
        <div v-if="projectTab && projectTabLink" class="submit-project-cta">
            <NuxtLink ref="nofollow" v-slot="{href, navigate}" :to="projectUrl" custom external>
                <span @click="navigate">
                    <VJoyIcon name="sendit" color="white" size="xsmall" />
                    <a :href="href">{{ projectTab }}</a>
                </span>
            </NuxtLink>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import SearchTabs from './SearchTabs.vue';
    import SearchTab from './SearchTab.vue';
    import SearchTabPanel from './SearchTabPanel.vue';
    import SearchForm from './SearchForm.vue';
    import {VJoyIcon} from '@maltjoy/core-vue';
    import {safelyNavigateTo} from '#malt/nuxt-utils-module';
    import {useTranslation} from '#imports';

    interface TabConfig {
        value: string;
        label: string;
        icon?: string;
        tag?: string;
        classes?: string;
        redirect?: string;
    }

    const props = defineProps<{
        searchTab?: string | null;
        searchFieldCategory?: string | null;
        searchFieldLocation?: string | null;
        searchLocationRemoteCountryLabel?: string | null;
        searchSubmit?: string | null;
        searchSubmitLink?: string | null;
        aiSearchTab?: string | null;
        aiSearchTabLink?: string | null;
        projectTab?: string | null;
        projectTabLink?: string | null;
        briefOrigin?: string | null;
    }>();

    const {t} = useTranslation();

    const activeTab = ref('keywords');
    const aiSearchUrl = ref('');
    const projectUrl = ref('');

    const tabs = ref<TabConfig[]>([
        {
            value: 'keywords',
            label: props.searchTab || '',
            icon: 'search',
        },
    ]);
    if (props.aiSearchTab && props.aiSearchTabLink) {
        aiSearchUrl.value = props.aiSearchTabLink;
        // TODO : remove condition after Prismic clean up
        if (!aiSearchUrl.value.includes('brief_origin')) {
            aiSearchUrl.value +=
                (aiSearchUrl.value.includes('?') ? '&' : '?') + `brief_origin=${props.briefOrigin}&pageSource=home_unlogged_try_ai_search`;
        }

        tabs.value.push({
            value: 'ai',
            label: props.aiSearchTab,
            icon: 'sparkle',
            tag: 'Beta',
            redirect: aiSearchUrl.value,
        });
    }
    if (props.projectTab && props.projectTabLink) {
        projectUrl.value = props.projectTabLink + (projectUrl.value.includes('?') ? '&' : '?') + `brief_origin=${props.briefOrigin}`;

        tabs.value.push({
            value: 'project',
            label: props.projectTab,
            icon: 'sendit',
            classes: 'hidden-mobile',
            redirect: projectUrl.value,
        });
    }

    function onTabClick(tab: typeof SearchTab.props) {
        if (tab.redirect) {
            const externalToThisApp = true; // TODO : detect if destination route belongs to the same app
            safelyNavigateTo(tab.redirect, {externalToThisApp});
            return;
        }
        activeTab.value = tab.value;
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .search-panel {
        width: 100%;
        .hidden-mobile {
            display: none;
            @include mq.screen_sm(min) {
                display: flex;
            }
        }
        .hidden-desktop {
            @include mq.screen_sm(min) {
                display: none;
            }
        }
    }

    .submit-project-cta {
        padding: var(--joy-core-spacing-8) 0;
        text-align: center;
        user-select: none;
        span {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            gap: var(--joy-core-spacing-2);
            font-weight: var(--joy-font-weight-bold);

            &:hover a {
                text-decoration: underline;
                text-underline-offset: 2px;
            }
        }
        a {
            color: white !important;
            letter-spacing: -0.2px;
        }

        @include mq.screen_sm(min) {
            display: none;
        }
    }
</style>
