import type {KeyTextField, LinkField} from '@prismicio/types';
import {useLogger, useUniversalFetch} from '#imports';
import {linkResolver} from '@navbar-unlogged/helpers';
import type {LocationSuggestion} from '@navbar-unlogged/types/custom';
import {useTypeGuards} from './useTypeGuards';

type GetSearchURLParams = {
    baseURLLink: LinkField | KeyTextField;
    query: string;
    location: LocationSuggestion | null;
    sourceComponent?: string; // to track the search_started Amplitude event
};

const QUERY_SUGGEST_URL = '/search/api/search/autocomplete';
const LOCATION_SUGGEST_URL = '/search/api/location/autocomplete';

const {hasProperty, isFilledKeyText, isFilledLink, isLocationSuggestionResponse} = useTypeGuards();

function getSearchURL({baseURLLink, query, location, sourceComponent}: GetSearchURLParams): string {
    if (baseURLLink === null || (!isFilledKeyText(baseURLLink) && !isFilledLink(baseURLLink))) {
        return window.location.href;
    }
    const baseURL = isFilledKeyText(baseURLLink) ? baseURLLink : baseURLLink.link_type === 'Document' ? linkResolver(baseURLLink) : baseURLLink.url;

    const searchParams = new URLSearchParams();
    searchParams.set('q', query);
    if (location) {
        searchParams.set('as', 't');
        for (const key in location) {
            const value: LocationSuggestion[keyof LocationSuggestion] | undefined = location[<keyof LocationSuggestion>key];
            if (value !== null && value !== undefined) {
                if (key === 'formattedAddress' && typeof value === 'string') {
                    searchParams.set('location', value);
                } else if (
                    key === 'loc' &&
                    hasProperty(value, 'lat') &&
                    typeof value.lat === 'number' &&
                    hasProperty(value, 'lon') &&
                    typeof value.lon === 'number'
                ) {
                    searchParams.set('lat', value.lat.toString());
                    searchParams.set('lon', value.lon.toString());
                } else if (typeof value === 'string' || typeof value === 'boolean') {
                    searchParams.set(key, value);
                }
            }
        }
    }

    if (sourceComponent) {
        searchParams.set('sourceComponent', sourceComponent);
    }

    return `${baseURL}${baseURL.includes('?') ? '&' : '?'}${searchParams.toString()}`;
}

async function getQueryAutocomplete(input: string): Promise<string[]> {
    const searchParams = new URLSearchParams();
    const QUERY_MAX_LENGTH = 100;
    const sanitizedInput = input.trim().slice(0, QUERY_MAX_LENGTH);
    searchParams.set('q', sanitizedInput);

    try {
        const response = await fetch(`${QUERY_SUGGEST_URL}?${searchParams.toString()}`);

        const data = await response.json();
        return data.suggestions;
    } catch (e) {
        const logger = useLogger();
        logger.error(e);
    }
    return [];
}

async function getLocationAutocomplete(input: string, countryCode: string = 'FR', lang: string = 'fr'): Promise<LocationSuggestion[]> {
    const payload = {
        query: input,
        layer: 'LOCALITY',
        languageTag: `${lang}-${countryCode}`,
        size: 5,
        useBackendfocusPoint: true,
    };
    const data = await useUniversalFetch(LOCATION_SUGGEST_URL, {baseURL: '/', method: 'POST', body: payload});

    if (isLocationSuggestionResponse(data)) {
        return data;
    }
    return [];
}

export function useSearchHelpers() {
    return {
        getSearchURL,
        getQueryAutocomplete,
        getLocationAutocomplete,
    };
}
