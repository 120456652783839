<template>
    <div class="search-tabs">
        <slot />
    </div>
</template>

<style>
    .search-tabs {
        display: flex;
        gap: var(--joy-core-spacing-2);
    }
</style>
