import type {LocationSuggestion} from '../types/custom';

function isArray(element: unknown): element is unknown[] {
    return Array.isArray(element);
}

function hasProperty<T extends string>(element: unknown, property: T): element is Record<T, unknown> {
    if (element === undefined || element === null) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(element, property);
}

function isArrayOf<T>(element: unknown, checker: (element: unknown) => element is T): element is T[] {
    if (element === undefined || element === null) {
        return false;
    }
    if (!Array.isArray(element)) {
        return false;
    }

    return element.every(checker);
}

function isHTMLElement(element: unknown): element is HTMLElement {
    return (
        element !== null &&
        element !== undefined &&
        (element instanceof HTMLElement || (element instanceof Node && element.nodeType === Node.ELEMENT_NODE))
    );
}

function isHTMLInputElement(target: EventTarget | null | undefined): target is HTMLInputElement {
    return target !== null && target !== undefined && target instanceof HTMLInputElement;
}

function isLocationSuggestion(element: unknown): element is LocationSuggestion {
    return hasProperty(element, 'formattedAddress');
}

function isLocationSuggestionResponse(response: unknown): response is LocationSuggestion[] {
    return isArrayOf(response, isLocationSuggestion);
}

function isLocationSuggestionRecord(record: any): record is Record<string, LocationSuggestion[]> {
    return (
        record !== null &&
        record !== undefined &&
        typeof record === 'object' &&
        Object.keys(record).every((key) => typeof key === 'string') &&
        Object.values(record).every(isLocationSuggestionResponse)
    );
}

export default function useTypeGuards() {
    return {
        hasProperty,
        isArray,
        isHTMLInputElement,
        isLocationSuggestionRecord,
        isLocationSuggestionResponse,
        isHTMLElement,
    };
}
