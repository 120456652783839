<template>
    <div ref="searchField" class="search-field" @click.stop="onClick">
        <slot />
    </div>
</template>

<script setup lang="ts">
    import {ref} from 'vue';

    const searchField = ref();

    function onClick() {
        searchField.value?.querySelector('input[type="text"]')?.focus();
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .search-field {
        position: relative;
        flex: 1;
        display: flex;
        gap: var(--joy-core-spacing-3);
        align-items: center;
        padding-left: var(--joy-core-spacing-5);
        padding-right: var(--joy-core-spacing-3);
        border-radius: var(--joy-core-radius-4);

        input {
            flex: 1;
            border: none;
            background-color: transparent;
            width: 100%;
            height: 100%;
            padding: 0;
        }

        &:has(input:focus) {
            box-shadow: var(--joy-core-elevation-2);
        }

        /* RESPONSIVE */
        @include mq.screen_sm(min) {
            border-radius: var(--joy-core-radius-5);
        }
    }
</style>
