import type {LocationQueryValue} from 'vue-router';
import {useRoute} from 'vue-router';

function optionalSingleString(param: LocationQueryValue | LocationQueryValue[]) {
    const params = [param].flat();
    return params.length > 0 ? params[0] : undefined;
}

export function useRouteSearchQueryParam() {
    const route = useRoute();
    return {
        q: optionalSingleString(route.query.q),
        location: optionalSingleString(route.query.location),
    };
}
