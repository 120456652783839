<template>
    <section class="header-search-slice" :class="{'search-page': isSearchPage}">
        <PrismicRichText :field="slice.primary.title" />
        <SearchPanel
            v-if="!isSearchPage && navbarSearchPanelV2"
            :search-tab="slice.primary.searchLinkText"
            :search-field-category="slice.primary.categoryLabel"
            :search-field-location="slice.primary.locationLabel"
            :search-location-remote-country-label="slice.primary.remoteInCountryLabel"
            :search-location-remote-europe-label="slice.primary.remoteInEuropeLabel"
            :search-submit="slice.primary.searchLinkText"
            :search-submit-link="slice.primary.searchLink"
            :ai-search-tab="slice.primary.aiSearchLinkText"
            :ai-search-tab-link="slice.primary.aiSearchLink"
            :project-tab="slice.primary.submitProjectLinkText"
            :project-tab-link="slice.primary.submitProjectLink"
            brief-origin="top_nav_search_results_unlogged"
        />
        <FreelanceSearch
            v-else
            :category-label="slice.primary.categoryLabel"
            :location-label="slice.primary.locationLabel"
            :search-link-text="slice.primary.searchLinkText"
            :search-link="slice.primary.searchLink"
            :submit-project-link-text="slice.primary.submitProjectLinkText"
            :submit-project-link="slice.primary.submitProjectLink"
            :ai-search-link-text="slice.primary.aiSearchLinkText"
            :ai-search-link="slice.primary.aiSearchLink"
            :between-ctas-text="slice.primary.betweenCTAsText"
            :missing-category-error-text="slice.primary.missingCategoryErrorText"
            :remote-in-country-label="slice.primary.remoteInCountryLabel"
            heap-prefix="navbar-unlogged-search-expand"
            brief-origin="top_nav_search_results_unlogged"
        />
    </section>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {HeaderSearchSliceSlice} from '@prismicio-types';
    import FreelanceSearch from '@navbar-unlogged/components/FreelanceSearch.vue';
    import SearchPanel from '@search/components/SearchPanel.vue';
    import {useFeatureFlagsStoreUnlogged} from '@navbar-unlogged/store/feature-flag-unlogged.store';
    import {useSearchRouteDefinitions} from '@navbar/features/search';

    defineProps(getSliceComponentProps<HeaderSearchSliceSlice>());

    const {features} = useFeatureFlagsStoreUnlogged();
    const navbarSearchPanelV2 = features['navbar-search-panel-v2'];

    const {currentPage} = useSearchRouteDefinitions();
    const isSearchPage = computed(() => currentPage.value?.name === 'Search');
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .header-search-slice {
        padding: 48px 80px 48px 80px;

        @include mq.screen_md {
            padding: 0;
            padding-top: var(--joy-core-spacing-8);
        }

        :deep(h2) {
            @include vars.secondary-title;
            line-height: var(--joy-line-height-large);
            text-align: left;
            margin-bottom: var(--joy-core-spacing-8);
        }

        :deep(.freelance-search) {
            margin-top: 24px;

            .search-card {
                box-shadow: none;
                padding: 0;

                .inputs {
                    .location-input,
                    .category-input,
                    .location,
                    .category {
                        max-width: none;
                    }
                }

                button[type='submit'] {
                    &:focus,
                    &:focus-visible {
                        border-color: var(--joy-color-secondary-30);
                    }

                    @include vars.button-primary-blue;

                    padding: 12px 40px;
                }
            }
        }
    }

    .search-page {
        padding: var(--joy-core-spacing-3) 80px;

        @include mq.screen_sm() {
            padding: var(--joy-core-spacing-3) 0px;
        }

        :deep(h2) {
            display: none;
        }

        :deep(section.freelance-search) {
            margin: 0;
        }
    }
</style>
