import {useCookie, useLogger} from '#imports';
import type {LocationSuggestion} from '../types/custom';
import useTypeGuards from '../composables/useTypeGuards';

type GetSearchURLParams = {
    baseURLLink: string;
    query: string;
    location: LocationSuggestion | null;
    sourceComponent?: string; // to track the search_started Amplitude event
};
const QUERY_SUGGEST_URL = '/search/api/search/autocomplete';
const LOCATION_SUGGEST_URL = '/search/api/location/autocomplete';

const {hasProperty, isLocationSuggestionResponse} = useTypeGuards();

function getSearchURL({baseURLLink, query, location, sourceComponent}: GetSearchURLParams): string {
    if (baseURLLink === null) {
        return window.location.href;
    }
    const baseURL = baseURLLink;

    const searchParams = new URLSearchParams();
    searchParams.set('q', query);
    if (location) {
        searchParams.set('as', 't');
        for (const key in location) {
            const value: LocationSuggestion[keyof LocationSuggestion] | undefined = location[<keyof LocationSuggestion>key];
            if (value !== null && value !== undefined) {
                if (key === 'formattedAddress' && typeof value === 'string') {
                    searchParams.set('location', value);
                } else if (
                    key === 'loc' &&
                    hasProperty(value, 'lat') &&
                    typeof value.lat === 'number' &&
                    hasProperty(value, 'lon') &&
                    typeof value.lon === 'number'
                ) {
                    searchParams.set('lat', value.lat.toString());
                    searchParams.set('lon', value.lon.toString());
                } else if (typeof value === 'string' || typeof value === 'boolean') {
                    searchParams.set(key, value);
                }
            }
        }
    }

    if (sourceComponent) {
        searchParams.set('sourceComponent', sourceComponent);
    }

    return `${baseURL}${baseURL.includes('?') ? '&' : '?'}${searchParams.toString()}`;
}

async function getQueryAutocomplete(input: string): Promise<string[]> {
    const searchParams = new URLSearchParams();
    const QUERY_MAX_LENGTH = 100;
    const sanitizedInput = input.trim().slice(0, QUERY_MAX_LENGTH);
    searchParams.set('q', sanitizedInput);

    try {
        const response = await fetch(`${QUERY_SUGGEST_URL}?${searchParams.toString()}`);

        const data = await response.json();
        return data.suggestions;
    } catch (e) {
        const logger = useLogger();
        logger.error(e);
    }
    return [];
}

async function getLocationAutocomplete(input: string, countryCode: string = 'FR', lang: string = 'fr'): Promise<LocationSuggestion[]> {
    const xsrfToken = useCookie('XSRF-TOKEN');
    try {
        const response = await fetch(LOCATION_SUGGEST_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-xsrf-token': xsrfToken.value ?? '',
            },
            body: JSON.stringify({
                query: input,
                layer: 'LOCALITY',
                languageTag: countryCode && lang ? `${lang}-${countryCode}` : 'en',
                size: 5,
                useBackendfocusPoint: true,
            }),
        });
        const data = await response.json();
        if (isLocationSuggestionResponse(data)) {
            return data;
        }
    } catch (e) {
        const logger = useLogger();
        logger.error(e);
        return [];
    }
    return [];
}

export default function useSearchHelpers() {
    return {
        getSearchURL,
        getQueryAutocomplete,
        getLocationAutocomplete,
    };
}
