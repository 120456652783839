<template>
    <div class="search-autocomplete">
        <input
            type="text"
            :value="query"
            :placeholder="placeholder"
            :maxlength="maxlength"
            autocomplete="off"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
        />
        <Transition name="fade-scale" mode="out-in">
            <div v-if="suggestions?.length && showSuggestions" class="search-autocomplete__suggestions" tabindex="-1">
                <div
                    v-for="(suggestion, i) in suggestions"
                    ref="suggestionsRefs"
                    :key="i"
                    :class="{'search-autocomplete--selected': i === listIndex}"
                    @mousedown.prevent.stop="select(i)"
                >
                    <slot name="suggestion" :suggestion="suggestion">
                        {{ suggestion }}
                    </slot>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import {ref, watch} from 'vue';
    import {onKeyStroke} from '@vueuse/core';

    const isFocused = ref(false);

    const {
        suggestions,
        modelValue,
        formatter = (val: any) => val,
    } = defineProps<{
        modelValue: any;
        placeholder?: string;
        suggestions?: string[] | Record<string, any>[];
        maxlength?: number;
        formatter?: (val: any) => string;
    }>();

    const emit = defineEmits(['update:modelValue', 'select']);

    const query = ref(formatter(modelValue) || '');
    const listIndex = ref(0);
    const blockSuggestions = ref(false);
    const showSuggestions = ref(false);
    const suggestionsRefs = ref<HTMLDivElement[]>([]);

    watch(
        () => suggestions,
        () => {
            if (suggestions?.length && !blockSuggestions.value) {
                showSuggestions.value = true;
            }
        },
    );

    function onInput(event: Event) {
        const {value} = event.target as HTMLInputElement;
        query.value = value;
        emit('update:modelValue', value);
    }

    function onFocus() {
        isFocused.value = true;
        if (suggestions?.length) {
            showSuggestions.value = true;
        }
    }

    function onBlur() {
        isFocused.value = false;
        showSuggestions.value = false;
    }

    function select(index: number) {
        blockSuggestions.value = true;
        showSuggestions.value = false;

        const s = suggestions![index];
        emit('update:modelValue', s);
        emit('select', s);
        query.value = formatter(s);

        setTimeout(() => {
            blockSuggestions.value = false;
        }, 250);
    }

    onKeyStroke(['ArrowUp', 'ArrowDown'], (e: KeyboardEvent) => {
        if (!showSuggestions.value) {
            return;
        }
        const increment = e.key === 'ArrowUp' ? -1 : 1;
        const length = suggestions!.length;
        listIndex.value = (listIndex.value + increment + length) % length;

        const selectedItem = suggestionsRefs.value[listIndex.value];
        selectedItem?.scrollIntoView({block: 'nearest', behavior: 'smooth'});
    });

    onKeyStroke('Enter', () => {
        if (showSuggestions.value) {
            select(listIndex.value);
        }
    });

    onKeyStroke('Escape', () => {
        showSuggestions.value = false;
    });
</script>
<style lang="scss">
    .search-autocomplete {
        input {
            text-overflow: ellipsis;
        }
        &__suggestions {
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: white;
            border-radius: var(--joy-core-radius-3);
            margin-top: var(--joy-core-spacing-3);
            color: var(--joy-color-neutral-60);
            // overflow: hidden;
            box-shadow: var(--joy-core-elevation-2);
            max-height: calc(5 * 36px);
            overflow: auto;

            > div {
                padding: var(--joy-core-spacing-2) var(--joy-core-spacing-5);
                cursor: default;

                &:hover {
                    background-color: var(--joy-color-secondary-10);
                    color: black;
                }
            }
        }

        &--selected {
            background-color: var(--joy-color-secondary-10);
            color: black;
        }
    }

    /* - Fade and scale transition */
    .fade-scale-enter-active,
    .fade-scale-leave-active {
        transition-property: opacity transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    .fade-scale-leave-active {
        transition-duration: 0.05s;
    }

    .fade-scale-enter-to {
        transform: scale(1);
    }

    .fade-scale-enter-from,
    .fade-scale-leave-to {
        opacity: 0;
        transform: scale(0.95);
    }
</style>
