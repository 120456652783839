<template>
    <SearchAutocomplete v-model="category" :maxlength="SEARCH_QUERY_MAX_LENGTH" :suggestions="suggestions" @input="emit('update:value', category)" />
</template>

<script setup lang="ts">
    import {ref, watch} from 'vue';
    import SearchAutocomplete from './SearchAutocomplete.vue';
    import useSearchHelpers from '../composables/useSearchHelpers';

    const SEARCH_QUERY_MAX_LENGTH = 128;
    const {getQueryAutocomplete} = useSearchHelpers();

    const emit = defineEmits(['update:value']);

    const category = ref('');
    const suggestions = ref<string[]>([]);

    watch(category, async () => {
        if (category.value?.length) {
            suggestions.value = await getQueryAutocomplete(category.value);
        } else {
            suggestions.value = [];
        }
    });
</script>
