<template>
    <SearchAutocomplete
        v-model="location"
        class="search-autocomplete-location"
        :suggestions="suggestions"
        :formatter="(suggestion: LocationSuggestion) => suggestion.formattedAddress"
    >
        <template #suggestion="{suggestion}">
            <VJoyIcon v-if="(suggestion as any).remoteAllowed" name="home" size="xsmall" class="search-autocomplete-location__icon" />
            {{ (suggestion as LocationSuggestion).formattedAddress }}
        </template>
    </SearchAutocomplete>
</template>

<script setup lang="ts">
    import {ref, watch} from 'vue';
    import {useLocale} from '#imports';
    import SearchAutocomplete from './SearchAutocomplete.vue';
    import useSearchHelpers from '../composables/useSearchHelpers';
    import useTypeGuards from '../composables/useTypeGuards';
    import {getDefaultCitiesSuggestionsForLocale} from '@malt/location-autocomplete/get-default-cities-suggestions';
    import {type LocationSuggestion} from '../types/custom';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const {getLocationAutocomplete} = useSearchHelpers();
    const {isLocationSuggestionResponse} = useTypeGuards();
    const {locale} = useLocale();

    const {remoteCountryLabel} = defineProps<{
        remoteCountryLabel?: string | null;
    }>();

    const location = ref();

    // Default suggestions
    const defaultSuggestions = ref<LocationSuggestion[]>([]);
    if (remoteCountryLabel && remoteCountryLabel !== '') {
        defaultSuggestions.value.push({formattedAddress: remoteCountryLabel, remoteAllowed: true});
    }

    const localeSuggestions: LocationSuggestion[] | undefined = getDefaultCitiesSuggestionsForLocale(locale.value);
    if (isLocationSuggestionResponse(localeSuggestions)) {
        defaultSuggestions.value.push(...localeSuggestions);
    }

    const suggestions = ref<LocationSuggestion[]>(defaultSuggestions.value);

    // Query changed
    watch(location, async () => {
        if (location.value?.length) {
            suggestions.value = await getLocationAutocomplete(location.value);
        } else {
            suggestions.value = defaultSuggestions.value;
        }
    });
</script>

<style lang="scss">
    .search-autocomplete-location {
        &__icon {
            position: relative;
            top: 2px;
        }
    }
</style>
