<template>
    <section class="freelance-search">
        <div class="search-card">
            <form class="search-form" data-testid="search-block-form" @submit="onSubmit">
                <div class="inputs">
                    <div class="category">
                        <input
                            v-model="query"
                            class="category-input"
                            name="category-input"
                            type="text"
                            :maxlength="SEARCH_QUERY_MAX_LENGTH"
                            data-testid="search-block-query-field"
                            :aria-label="categoryLabel || 'category'"
                            :label="categoryLabel"
                            :placeholder="categoryLabel || 'category'"
                            autocomplete="off"
                            list="query-list"
                            debounce="500"
                            @input="onQueryInputChange"
                            @focus="() => (isQuerySearchActive = true)"
                            @blur="onQueryBlur"
                        />
                        <button
                            :class="{'clear-button': true, visible: query?.length > 0}"
                            type="button"
                            data-testid="search-block-query-clear"
                            aria-label="clear-query"
                            @click="clearQuery"
                        >
                            <VJoyIcon name="cross" />
                        </button>
                        <span v-if="categoryIsInError" class="category-error">{{ missingCategoryErrorText }}</span>
                        <div
                            :class="{
                                suggestions: true,
                                visible: isQuerySearchActive && querySuggestions.length > 0,
                            }"
                            data-testid="search-block-query-suggestions"
                        >
                            <ul>
                                <li
                                    v-for="querySuggestion in querySuggestions"
                                    :key="querySuggestionOutput(querySuggestion, 'key')"
                                    @mousedown="(event) => onQuerySuggestionClick(event, querySuggestion)"
                                >
                                    {{ querySuggestion }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="location">
                        <input
                            v-model="locationInput"
                            class="location-input"
                            name="location-input"
                            type="text"
                            data-testid="search-block-location-field"
                            :aria-label="locationLabel || 'location'"
                            :label="locationLabel"
                            :placeholder="locationLabel || 'location'"
                            autocomplete="off"
                            @input="onLocationInputChange"
                            @focus="() => (isLocationSearchActive = true)"
                            @blur="onLocationBlur"
                        />
                        <button
                            :class="{
                                'clear-button': true,
                                visible: locationInput?.length > 0,
                            }"
                            type="button"
                            data-testid="search-block-location-clear"
                            aria-label="clear-location"
                            @click="clearLocation"
                        >
                            <VJoyIcon name="cross" />
                        </button>
                        <div
                            :class="{
                                suggestions: true,
                                visible: isLocationSearchActive && locationSuggestions.length > 0,
                            }"
                            data-testid="search-block-location-suggestions"
                        >
                            <ul>
                                <li
                                    v-for="locationSuggestion in locationSuggestions"
                                    :key="locationSuggestion.formattedAddress"
                                    @mousedown="(event) => onLocationSuggestionClick(event, locationSuggestion)"
                                >
                                    <VJoyIcon v-if="hasProperty(locationSuggestion, 'remoteAllowed')" name="home" />
                                    {{ locationSuggestion.formattedAddress }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="links">
                    <VJoyButton type="submit" class="search-form__submit" data-testid="search-block-btn-submit">
                        {{ searchLinkText }}
                    </VJoyButton>
                    <div v-if="aiSearchLinkText" class="ai-search-cta">
                        <span v-if="betweenCtasText" class="between-ctas">{{ betweenCtasText }}</span>
                        <VJoyButton
                            :href="aiSearchLink"
                            rel="nofollow"
                            icon="sparkle"
                            icon-position="right"
                            variant="secondary-ai"
                            type="button"
                            data-testid="search-block-btn-ai-search"
                        >
                            {{ aiSearchLinkText }}
                        </VJoyButton>
                    </div>
                    <div v-if="displaySubmitProjectLink && submitProjectLink && (!user || !user.connected || isCompany)" class="submit-project">
                        <span class="cta-separator" />
                        <a :href="submitProjectLinkWithParams">{{ submitProjectLinkText }}</a>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {useLocale} from '#imports';
    import {defaultCitiesSuggestions} from '@malt/location-autocomplete/get-default-cities-suggestions';
    import {VJoyButton, VJoyIcon} from '@maltjoy/core-vue';
    import {useSearchHelpers, useTypeGuards} from '@navbar-unlogged/composables';
    import {useRouteSearchQueryParam} from '@navbar-unlogged/composables/useRouteSearchQueryParam';
    import {useUser} from '@navbar-unlogged/store/user.store';
    import type {LocationSuggestion, QuerySuggestion} from '@navbar-unlogged/types/custom';
    import {useSearchRouteDefinitions} from '@navbar/features/search';
    import type {KeyTextField} from '@prismicio/types';
    import {storeToRefs} from 'pinia';
    import {computed, ref} from 'vue';
    import {useFeatureFlagsStoreUnlogged} from '../store/feature-flag-unlogged.store';

    const SEARCH_QUERY_MAX_LENGTH = 128;

    // Stores
    const {features} = storeToRefs(useFeatureFlagsStoreUnlogged());
    const displaySubmitProjectLink = computed((): boolean => features.value['project-proposal-brief-no-more-service-level-needed'] || false);

    type Props = {
        categoryLabel: KeyTextField;
        locationLabel: KeyTextField;
        searchLinkText: KeyTextField;
        searchLink: KeyTextField;
        submitProjectLinkText: KeyTextField;
        submitProjectLink: KeyTextField;
        aiSearchLinkText: KeyTextField;
        aiSearchLink: KeyTextField;
        betweenCtasText: KeyTextField;
        missingCategoryErrorText: KeyTextField;
        heapPrefix: string;
        remoteInCountryLabel: KeyTextField;
        briefOrigin?: string;
    };

    const props = withDefaults(defineProps<Props>(), {
        submitProjectLinkText: '',
        submitProjectLink: '',
        aiSearchLinkText: '',
        aiSearchLink: '',
        betweenCtasText: '',
        missingCategoryErrorText: '',
        heapPrefix: '',
        remoteInCountryLabel: '',
        briefOrigin: '',
    });

    const {locale} = useLocale();
    const [lang, countryCode] = locale.value?.split('-') ?? ['fr', 'FR'];

    const {user, isCompany} = storeToRefs(useUser());

    const {hasProperty, isHTMLInputElement, isLocationSuggestionRecord, isLocationSuggestionResponse, isFilled} = useTypeGuards();
    const {getLocationAutocomplete, getQueryAutocomplete, getSearchURL} = useSearchHelpers();
    const defaultLocationSuggestions: LocationSuggestion[] = [];

    const {currentPage} = useSearchRouteDefinitions();

    if (props.remoteInCountryLabel && props.remoteInCountryLabel !== '') {
        defaultLocationSuggestions.push({
            formattedAddress: props.remoteInCountryLabel,
            remoteAllowed: true,
        });
    }

    if (isLocationSuggestionRecord(defaultCitiesSuggestions)) {
        // eslint-disable-next-line import/namespace
        const localeSuggestions: LocationSuggestion[] | undefined = defaultCitiesSuggestions[countryCode];

        if (isLocationSuggestionResponse(localeSuggestions)) {
            defaultLocationSuggestions.push(...localeSuggestions);
        }
    }

    const searchQueryParams = useRouteSearchQueryParam();

    const query = ref(searchQueryParams.q || '');
    const locationInput = ref(searchQueryParams.location || '');
    const location = ref<LocationSuggestion | null>(null);
    const submitProjectLinkWithParams = ref<string>(computeSubmitProjectLinkWithParams());
    const categoryIsInError = ref(false);
    const querySuggestions = ref<string[]>([]);
    const isQuerySearchActive = ref(false);
    const locationSuggestions = ref<LocationSuggestion[]>(defaultLocationSuggestions);
    const isLocationSearchActive = ref(false);

    function querySuggestionOutput(querySuggestion: QuerySuggestion | string, field: 'key' | 'text'): string {
        return typeof querySuggestion === 'string' ? querySuggestion : querySuggestion[field];
    }

    function onSubmit(e: Event) {
        e.preventDefault();

        if (!isFilled(query.value)) {
            categoryIsInError.value = true;
            return;
        } else {
            categoryIsInError.value = false;
        }

        if (currentPage.value?.name === 'Search') {
            const searchEvent = new CustomEvent('navbar:searchRequested', {
                bubbles: true,
                detail: {
                    query: query.value,
                    location: location.value,
                    sourceComponent: 'navbar_search',
                },
            });
            document.dispatchEvent(searchEvent);
        } else {
            window.location.href = getSearchURL({
                baseURLLink: props.searchLink,
                query: query.value,
                location: location.value,
                sourceComponent: 'navbar_search_expanded',
            });
        }
    }

    async function onQueryInputChange(event: Event) {
        if (!isHTMLInputElement(event.target)) {
            return;
        }

        query.value = event.target.value ?? '';

        if (props.submitProjectLink) {
            submitProjectLinkWithParams.value = getSearchURL({
                baseURLLink: props.submitProjectLink,
                query: query.value,
                location: location.value,
                sourceComponent: 'navbar_search_expanded',
            });
        }

        if (event.target.value.length >= 2) {
            querySuggestions.value = await getQueryAutocomplete(event.target.value);
            isQuerySearchActive.value = true;
        } else {
            querySuggestions.value = [];
        }
    }

    function onQuerySuggestionClick(event: Event, querySuggestion: string) {
        event.preventDefault();
        query.value = querySuggestion;

        setTimeout(() => {
            isQuerySearchActive.value = false;
        }, 100);
    }

    function onQueryBlur() {
        isQuerySearchActive.value = false;
    }

    function clearQuery() {
        query.value = '';
        querySuggestions.value = [];
    }

    async function onLocationInputChange(event: Event) {
        if (!isHTMLInputElement(event.target)) {
            return;
        }

        locationInput.value = event.target.value ?? '';

        if (event.target.value.length >= 2) {
            locationSuggestions.value = await getLocationAutocomplete(event.target.value, countryCode, lang);
        } else {
            locationSuggestions.value = defaultLocationSuggestions;
        }

        if (event.target.value.length === 0) {
            location.value = null;
        }
        isLocationSearchActive.value = true;
    }

    function onLocationSuggestionClick(event: Event, locationSuggestion: LocationSuggestion) {
        event.preventDefault();
        location.value = locationSuggestion;
        locationInput.value = locationSuggestion.formattedAddress;

        setTimeout(() => {
            isLocationSearchActive.value = false;
        }, 200);
    }

    function onLocationBlur() {
        locationInput.value = location.value?.formattedAddress ?? '';
        if (!location.value) {
            locationSuggestions.value = defaultLocationSuggestions;
        }
        isLocationSearchActive.value = false;
    }

    function clearLocation() {
        location.value = null;
        locationInput.value = '';
        locationSuggestions.value = defaultLocationSuggestions;
    }

    function computeSubmitProjectLinkWithParams() {
        if (props.submitProjectLink) {
            if (props.briefOrigin) {
                const url = props.submitProjectLink;
                if (!url.includes('brief_origin')) {
                    return `${url}${url.includes('?') ? '&' : '?'}brief_origin=${props.briefOrigin}`;
                }
            }

            return props.submitProjectLink;
        }
        return '';
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    $max-input-width: none;
    $min-input-width: 200px;

    .freelance-search {
        @include vars.flex-column-align-center;
        width: 100%;
        font-family: var(--joy-font-family);

        @include mq.screen_sm {
            @include vars.card-without-transition;
            padding: 19px 17px 29px 17px;
            align-items: flex-start;
            background-color: white;
            box-shadow: 0px 4px 12px rgba(24, 24, 24, 0.16);
        }

        .search-card {
            @include vars.flex-row-center;
            @include vars.card-without-transition;
            background-color: white;
            gap: 16px;
            flex-wrap: wrap;
            padding: 32px;
            width: 100%;

            @include mq.screen_sm {
                flex-direction: column;
                padding: 0;
                box-shadow: none;
            }

            .search-form {
                @include vars.flex-row-justify-center;
                align-items: baseline;
                flex-wrap: wrap;
                gap: 20px;
                width: 100%;

                @include mq.screen_sm {
                    flex-direction: column;
                    gap: 32px;
                }

                .joy-button {
                    @include mq.screen_sm {
                        width: 100%;
                    }
                }
            }

            .inputs {
                display: flex;
                gap: 20px;
                width: 100%;
                flex: 1 1 0;

                @include mq.screen_sm {
                    flex-direction: column;
                    gap: 16px;
                }

                .suggestions {
                    position: absolute;
                    z-index: 10;
                    top: 52px;
                    width: 100%;
                    background-color: white;
                    list-style: none;
                    border-radius: var(--joy-core-radius-4);
                    border: solid 2px var(--joy-color-secondary-30);
                    overflow: hidden;
                    display: none;
                    color: var(--joy-color-neutral-50);

                    &.visible {
                        display: block;
                    }

                    ul {
                        max-height: 185px;
                        overflow: auto;
                        padding: 0;
                        margin: 0;

                        li {
                            padding: 10px 15px;
                            font-size: var(--joy-font-size-primary-400);
                            line-height: 17px;

                            &:hover {
                                background-color: var(--joy-color-secondary-10);
                            }

                            display: flex;
                            align-items: baseline;
                            gap: 8px;
                        }

                        @include vars.nicer-scrollbar;
                    }
                }

                .clear-button {
                    position: absolute;
                    right: var(--joy-core-spacing-4);
                    top: var(--joy-core-spacing-4);
                    cursor: pointer;
                    background: transparent;
                    display: none;
                    border: none;

                    &.visible {
                        display: block;
                    }
                }

                .category {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    max-width: $max-input-width;
                    position: relative;

                    @include mq.screen_sm {
                        max-width: none;
                        width: 100%;
                    }

                    .category-input {
                        width: 100%;
                        background-image: url('//dam.malt.com/navbar/icons/header/unlogged/search-gray.svg');
                        &:focus {
                            background-image: url('//dam.malt.com/navbar/icons/header/unlogged/search.svg');
                        }

                        @include mq.screen_sm {
                            max-width: none;
                            min-width: auto;
                        }
                    }

                    .category-error {
                        color: var(--joy-color-primary-30);
                        margin-top: 10px;
                    }
                }

                .location {
                    width: 50%;
                    max-width: $max-input-width;
                    position: relative;

                    @include mq.screen_sm {
                        max-width: none;
                        width: 100%;
                    }

                    .location-input {
                        width: 100%;
                        background-image: url('//dam.malt.com/navbar/icons/header/unlogged/location-gray.svg');
                        &:focus {
                            background-image: url('//dam.malt.com/navbar/icons/header/unlogged/location.svg');
                        }

                        @include mq.screen_sm {
                            max-width: none;
                            min-width: auto;
                        }
                    }
                }
            }

            .links {
                display: flex;
                align-items: center;
                max-height: 48px;
                gap: 16px;

                @include mq.screen_sm {
                    @include vars.flex-column-align-center;
                    width: 100%;
                    gap: 30px;
                    max-height: none;
                }
            }

            .submit-project {
                display: flex;
                align-items: center;
                gap: 16px;
                a {
                    @include vars.link;
                }
            }

            .ai-search-cta {
                width: 100%;

                @include mq.screen_md(min) {
                    width: auto;
                }
            }

            input {
                @include vars.input;
                font-weight: var(--joy-font-weight-bold);
                line-height: var(--joy-line-height-large);

                &::placeholder {
                    font-weight: var(--joy-font-weight-normal);
                }

                &[type='text'] {
                    max-width: $max-input-width;
                    min-width: $min-input-width;
                    width: 100%;
                    height: 48px;
                    padding: 12px 45px;
                    background-repeat: no-repeat;
                    background-size: 21px auto;
                    background-position: 15px center;
                    color: var(--joy-color-neutral-50);
                }
            }

            .between-ctas {
                margin-right: 16px;
                color: var(--joy-color-neutral-50);
                font-size: var(--joy-font-size-primary-400);
                @include mq.screen_sm() {
                    display: none;
                }
            }
        }
        .cta-separator {
            display: none;
            @include mq.screen_md(min) {
                display: block;
                height: 48px;
                width: 1px;
                background: var(--joy-color-neutral-30);
            }
        }
    }
</style>
